import React from "react"
import BackgroundImage from './BackgroundImage'

export default ({ pageTitle, bgImage }) => (
  <BackgroundImage bgImagePath={bgImage}>
    <header className="hero">
      <div className="container">
        <div className="focus">
          <h1 className="fade-in one">{pageTitle}</h1>
        </div>
      </div>
    </header>
  </BackgroundImage>
)