import React from "react"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import Header from "../../components/Header"

export default () => (
	<Layout>
		<SEO pageTitle="Terms and conditions" />
		<Header pageTitle="Terms and conditions" bgImage="backgrounds/header.jpg" />
		<section className="body-text">
			<div className="container">
				<h2>Background</h2>
				<p>These Terms and Conditions are the standard terms which apply:</p>
				<ol style={{listStyleType: 'upper-alpha'}}>
						<li>To provision to customers any services or products (&ldquo;services/products&rdquo; as defined in clause 1 below) by the salon, namely Birds of Beauty Parlour, 21 Westdale Crescent, Carlton, Nottingham, NG4 3JP.</li>
						<li>Where the customer is a &ldquo;consumer&rdquo; as defined by the Consumer Rights Act 2015.</li>
				</ol>
				<h2>1.0 Definitions and Interpretation</h2>
				<p>In these Terms and Conditions, unless the context otherwise requires, the following expressions have the following meanings:</p>
				<ul>
						<li><strong>&ldquo;Business&rdquo;</strong> means any business, trade, craft, or profession carried out by the salon, or any other person or organisation</li>
						<li><strong>&ldquo;Consumer&rdquo;</strong> means a consumer as defined by the Consumer Rights Act 2015 and in relation to these Terms and Conditions means an individual customer of the salon who has received services and/or products for the customers&rsquo; personal use and for purposes wholly or mainly outside the purposes of any business</li>
						<li><strong>&ldquo;Regulations&rdquo;</strong> means The Consumer Contracts (Information, Cancellation and Additional charges) Regulations 2013</li>
						<li><strong>&ldquo;Salon/We/Us/Our&ldquo;</strong> means Birds of Beauty Parlour whose place of business and contact address is the same as above and includes all employees and agents of the salon</li>
						<li><strong>&ldquo;Services/Products&rdquo;</strong> means any/all of the treatments, facilities, services, products and other goods and materials which we provide, use or sell</li>
						<li><strong>&ldquo;You/Your&rdquo;</strong> means an individual who is a customer of the salon</li>
				</ul>
				<p>1.2 Unless the context otherwise requires, each reference in these Terms and Conditions to:</p>
				<p>&ldquo;These Terms and Conditions&rdquo; is a reference to these Terms and Conditions herein, and a clause or sub clause is a reference to a clause of these Terms and Conditions.</p>
				<p>1.3 The headings used in these Terms and Conditions are for convenience only and shall not affect the interpretation of these Terms and Conditions.</p>
				<p>1.4 Words signifying the singular number shall include the plural and vice versa and</p>
				<p>1.5 References to any gender shall include all other pro-nouns.</p>
				<h2>2.0 Appointment/bookings</h2>
				<p>2.1 The treatment services We offer are only available by appointment, appointments can be made by email, using our online booking service, via our Facebook or Instagram pages, in person at the salon or via telephone.</p>
				<p>2.2 Requests for a treatment booking appointment will be an offer, but whether We accept any booking will be for Us to decide at Our discretion.  Only if and when We confirm with You that We accept Your request for a particular appointment, will there be a binding contract between You and Us. If two or more appointments are requested, by means of a single booking and in Our discretion, We accept that booking, Our contract with You will be for all of the appointments concerned.</p>
				<p>2.3 When You book an appointment, We may require You to pay Us a booking fee, some or all of which we will be entitled to keep as set out in subclause 2.7 below, if You later cancelled the appointment without giving Us prior notice of at least 24 hours. Booking fee deposit&apos;s shall be equal to no more than 50% of the price of the services required.</p>
				<p>2.4 We will not reserve or guarantee any particular date and or time which you request for any services, unless you book an appointment for that time and date.</p>
				<p>2.5 If You know You are going to be late for an appointment, You should contact Us to inform Us. If You arrive later than 10 minutes after an appointment start time, We will endeavour to provide the services booked, but if We decide that we cannot fulfil the service in the reduced time, the appointment will be treated as cancelled without notice by You and if we then decide to make a charge for that appointment, cancelled without notice, subclause 2.7 below will apply.</p>
				<p>2.6 You may cancel an appointment without charge if You give us at least 24 hours prior notice of the cancellation and if You do so, We will refund You any sum, including but not limited to any booking fee deposit You paid in advance.</p>
				<p>2.7 If You do not give Us at least 24 hours prior notice of cancellation of an appointment, we will be entitled to charge You for any net financial loss that We suffer due to Your cancellation but not more than 100% of the full price of the appointment. We will be entitled to deduct that charge from any sum (including, but not limited to any booking fee/deposit) You paid in advance, and We shall refund the balance to You.</p>
				<p>2.8 If, due to exceptional circumstances including, but not limited to illness, accidents and bereavement, You cancel an appointment without giving Us at least 24 hours prior notice, We will consider the circumstances and in Our discretion decide whether to waive any charge for late cancellation that We are entitled to make under sub clauses 2.5 and 2.7</p>
				<p>2.9 We may cancel an appointment booked by You at any time before the time and date of that appointment in the following circumstances</p>
				<ul>
						<li>The required personnel and/or required materials necessary for the provision of the services are not available; or</li>
						<li>An event outside of our reasonable control continues for more than one hour; or</li>
						<li>We find that you are not a Consumer as defined in clause one above</li>
				</ul>
				<p>If We cancel an appointment in such circumstances, We will refund You in full any booking fee/deposit or other advanced payments that you have made to Us for that appointment;</p>
				<p>2.10 We will use all reasonable endeavours to start the services at the appointment time which you have bought, but the start may be delayed by overrun of a previous appointment or by other circumstances, if a delay to the start is at least 30 minutes, or if at any time before or after You arrive for an appointment, We will notify you that there will be a delay of at least that time. You may cancel the appointment and We will refund to You in full any booking fee/deposit or other advanced payment that you have made to Us for that appointment; and</p>
				<p>2.11 Where the contract We make with You is not made on Our premises, the Regulations give You the rights set out in this sub clause 2.11, and they will be in addition to the rights given to You by the above provisions of this clause 2. You may for any reason cancel a booking during the 14 day period after We accept that booking, but if the booking includes any appointment(s) on a date which is before the end of that period, and if You have expressly requested Us to provide any services at that or those appointment(s) and We do so, You may not cancel that or those requested appointment(s) and you must pay for them in accordance with clause 3, and You may only cancel any other appointments covered by that booking. If You request that Your booking be cancelled, You must confirm this in anyway convenient to You. If You cancel as allowed by this sub clause 2.11 and You have already made any payments to us for that booking We will refund the payments to You within 14 days of receiving Your cancellation less the amount due for the appointments covered by that booking that You have attended.</p>
				<h2>3.0 Fees and Payments</h2>
				<p>3.1 You must pay in accordance with Our price list for all services and/or products on completion of those that We have fully and correctly provided to You and at the time of Your order.</p>
				<p>3.2 You may pay Us for services and/or products and for any booking fee/deposit or other advanced payment on account of that payment using any of the following methods:</p>
				<ul>
						<li>credit or debit card</li>
						<li>cash Sterling</li>
						<li>bank transfer or</li>
						<li>gift voucher</li>
				</ul>
				<p>3.3 We may alter Our prices without prior notice, but if the price of any services increases between the time when You book an appointment and the date of the appointment, the price increase will not apply to Your appointment for those services on that date; and</p>
				<p>3.4 All prices of services or products shown in the price list are inclusive of VAT.</p>
				<h2>4.0 Eligibility for Treatment</h2>
				<p>4.1 You confirm that, in connection with Your request(s) to receive any services from Us, You are and will be a consumer as defined in clause 1 above;</p>
				<p>4.2 We will not provide certain treatments to You if you are aged under 18, unless you have parental consent. We may require evidence of your age for that purpose.</p>
				<p>4.3 If you have medical condition(s) certain treatments may be unsuitable for You. We advise You to tell Us if any medical condition or ongoing medical treatment(s) when You book an appointment if You do not tell Us at that time We will be entitled not to provide a treatment, or part of it, and to treat the appointment or the affected part of it as cancelled by You without notice in which case We may make a charge to You for the cancelled appointment or part of it as set out in subclause 2.6 above</p>
				<p>4.4 If You suffer from any allergy or a skin condition We may require You to take a patch test before We provide certain treatments, and;</p>
				<p>4.5 We advise You not to have any waxing, permanent makeup and or facial treatments immediately before or after heat treatments or exercise, and;</p>
				<p>4.6 If you are pregnant, We advise You not to have permanent makeup, lash lift, tinting.</p>
				<h2>5.0 Patch Tests</h2>
				<p>5.1 Any treatment that requires a patch test will not be carried out until a patch test has been carried out. </p>
				<p>5.2 All Patch tests need to be done either 24 or 48 hours prior to your appointment.</p>
				<p>5.3 If you have a reaction to a patch test you must inform us immediately.</p>
				<p>5.4 Failing to agree to a Patch test will result in the appointment automatically being cancelled.</p>
				<h2>6.0 Salon Rules</h2>
				<p>We do not permit you to;</p>
				<p>6.1 Smoke within the salon premises, or make or receive mobile phone calls during Your treatment.</p>
				<p>6.2 Be accompanied at the salon&rsquo;s premises for appointments, by any children who are themselves not having any treatments unless they&apos;re supervised by an appropriate adult as we do not have an appropriate member of staff to supervise them.</p>
				<h2>7.0 Gift Vouchers</h2>
				<p>7.1 Gift vouchers are available online from <a href="https://www.robovoucher.com/buy/list/UK4GCS-2231">https://www.robovoucher.com/buy/list/UK4GCS-2231</a> or on site at the salon.</p>
				<p>7.2 Gift vouchers are available in 10, 20, 25, 50 or 100 or for a specified treatment.</p>
				<p>7.3 Gift vouchers are valid for the period specified on the gift voucher</p>
				<p>7.4 Gift vouchers may be redeemed only for treatments or products which We provide at the Salon and may not be redeemed partly or wholly for cash.</p>
				<h2>8.0 Recommend a Friend</h2>
				<p>8.1 Cards offered to existing clients to pass on to friends or family members that have never been to the salon before.</p>
				<p>8.2 The new client is entitled to 25% off their first treatment after which the existing client is entitled to 25% off their next treatment.</p>
				<h2>9.0 Events Outside of our Control</h2>
				<p>9.1 We will not be liable or responsible for any failure to perform or delay in performance of, any of Our obligations under a contract that is caused by events outside Our reasonable control (a &lsquo;Force Majeure Event).</p>
				<p>9.2 Our performance under any contract is deemed to be suspended for the period that the Force Majeure Event continues, and We will have an extension of time for performance for the duration of that period. We will use our reasonable endeavours to bring the Force Majeure Event to a close, or to find a solution by which Our obligations under the contract may be performed despite the Force Majeure Event.</p>
				<h2>10.0 Limitation of Liability</h2>
				<p>10.1 We will be responsible for any foreseeable loss or damage that You may suffer as a result of Our breach of these Terms and Conditions, or as a result of Our negligence. Loss or damage is foreseeable if it is an obvious consequence of Our breach or negligence or if it is contemplated by You and Us when our contract with You is created We will not be responsible for any loss or damage that is not foreseeable.</p>
				<p>10.2 We provide or sell all services and products to You only for Your personal and private use purposes. We make no warranty or representation that products or other goods or materials that We provide or sell to You are fit for commercial business, industrial, trade, craft or professional purposes of any kind including resale. We will not be liable to You for any loss of profits, loss of business, interruption to business or for any loss of business opportunity.</p>
				<p>Nothing in these Terms and Conditions is intended to or will exclude or limit Our liability for death or personal injury caused by Our negligence including that of Our employees, agents or subcontractors or for fraud or fraudulent misrepresentation.</p>
				<p>Furthermore, if You are a consumer as defined by the Consumer Rights Act 2015 or a consumer for the purposes of any other consumer protection legislation, nothing in these Terms and Conditions is intended to or will exclude, limit, prejudice or otherwise affect any of Our duties or obligations to You, or Your rights or remedies or Our liability to You under the Consumer Rights Act 2015, the Consumer Protection Act 1987 or any other consumer protection legislation, as that legislation is amended from time to time.</p>
				<p>For more details of Your legal rights, please refer to Your legal Citizens Advice Bureau or Trading Standards Office.</p>
				<h2>11.0 Changes to Terms and Conditions</h2>
				<p>We may from time to time change these Terms and Conditions without giving You notice but We will use Our reasonable endeavours to inform You as soon as is reasonably possible of any change.</p>
				<h2>12.0 How we use your personal information - data protection</h2>
				<p>All personal information that We may use will be collected, processed and held in accordance with the provisions of EU regulation 2016 679 General Data Protection Regulation GDPR and Your rights under the GDPR for complete details of Our collection processing, storage and retention of personal data including but not limited to the purposes for which personal data is used, the legal basis or bases for using it, details of Your rights and how to exercise them and personal data sharing applicable, please refer to Our privacy notice available on this website.</p>
				<h2>13.0 Regulations</h2>
				<p>We are required by the regulations to ensure that certain information is given or made available to You as a consumer before We make Our contract with You i.e. before We accept Your request to make a booking, except where that information is already apparent from the context of the transaction, We have included the information itself either in these Terms and Conditions for You to see now, or We will make it available to You before We accept Your request to make a booking, all of that information will, as required by the regulations, be part of the terms of our contract with You as a consumer.</p>
				<h2>14.0 Information</h2>
				<p>As required by the regulations:</p>
				<p>14.1 All of the information described in clause 14, and</p>
				<p>14.2 Any other information which We give to You about any services, products, or the salon, which You take into account when deciding to make a booking, use Our online shop, or when making any other decision about the services, will be part of the terms of Our Contract with You as the consumer.</p>
				<h2>15.0 Complaints</h2>
				<p>We always welcome feedback from Our customers and whilst We always use all reasonable endeavours to ensure that Your experience as a customer of Ours is a positive one, We nevertheless want to hear from You if You have any cause for complaints. If You have any complaint about Our services, or any other complaints about the salon, please raise the matter with the salon owner, who can be contacted at the salon or by email at birdsofbeautyparlour@gmail.com</p>
				<h2>16.0 No Waiver</h2>
				<p>No failure or delay by Us or You in exercising any rights under these Terms and Conditions means that We or You have waived that right, and no waiver by Us or You of a breach of any provision of these Terms and Conditions means that We or You will waive any subsequent breach of the same or any other provision.</p>
				<h2>17.0 Severance</h2>
				<p>If any provision of these Terms and Conditions is held by any competent authority to be invalid or unenforceable in whole or in part, the validity of the other provisions of these Terms and Conditions and the remainder of the provision in question shall not be affected.</p>
				<h2>18.0 Law and Jurisdiction</h2>
				<p>18.1 These Terms and Conditions, the Contract and the relationship between You and Us, whether contractual or otherwise, shall be governed by and construed in accordance with the law of England and Wales.</p>
				<p>18.2 As a consumer You will benefit from any mandatory provisions of the law in Your country of residence. Nothing in subclause 12.1 above takes away or reduces Your rights as a Consumer to rely on these provisions, and it disputes controversy proceedings or a claim between You and Us, relating to these Terms and Conditions the Contract or the relationship between You and Us whether contractual or otherwise, shall be subject to the jurisdiction of the courts of England, Wales, Scotland, or Northern Ireland, as determined by Your residency.</p>
			</div>
		</section>
	</Layout>
)